import React, { useMemo } from 'react';
import './popup.iframe.scss';

const getGoogle360Url = (embedScript) => {
  const pattern = /(src=".*?")/gm;
  const result = pattern.exec(embedScript);
  let str = result && result[0];
  if (!str) return null;
  str = str.replace('src="', '');
  if (!str) return null;
  str = str.substr(0, str.length - 1);
  return str;
};

const getGoogle360Iframe = (url) => `
  <iframe src="${url}" frameborder="0"></iframe>
`;

const getHtml = (url) => ({
  __html: getGoogle360Iframe(url),
});

function PopupGoogle360({ media }) {
  console.log(media);

  const url = useMemo(() => {
    const data = (media && media.google360 && media.google360.url) || null;
    if (!data) {
      return null;
    }
    return getGoogle360Url(data);
  }, [media]);
  if (!url) {
    return;
  }
  // eslint-disable-next-line
  const embedHtml = getHtml(url);

  return (
    <div className={`Popup-content Popup-content-iframe`}>
      <div className="Popup-content-wp">
        <iframe
          title="iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.6542884341725!2d106.7424609!3d11.048712499999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174dad01fb76597%3A0x21f82005ed3bd355!2sBrothers%20Furniture%20Co.%20Ltd!5e1!3m2!1sen!2s!4v1732173225820!5m2!1sen!2s"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default PopupGoogle360;
