import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkPassword } from 'utils/apiHelper';

import './style.scss';

const PopupPasscode = (props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const { onClose, tourPasscode } = props;
  const [passcode, setPasscode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const checkIsTimeUp = (dataDate) => {
    const usedDate = new Date(dataDate);
    const currentDate = new Date();
    const daysDifference = Math.abs(
      (currentDate - usedDate) / (1000 * 60 * 60 * 24)
    );

    return daysDifference > 1;
  };

  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = await checkPassword({
        password: passcode,
      });

      if (data?.isSuccess) {
        const encodedPasscode = btoa(passcode);

        const usedDate = btoa(data.data.usedDate);
        localStorage.setItem('_abcxyz', encodedPasscode);
        localStorage.setItem('_abc123', usedDate);
        handleClose();
      } else {
        setErrorMessage(data?.message || 'An unexpected error occurred');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An unexpected error occurred');
    }
  };

  const handleClose = () => {
    setPasscode('');
    setErrorMessage('');
    onClose();
  };

  const isValidDate = (value) => {
    const date = new Date(value);
    return (
      Object.prototype.toString.call(date) === '[object Date]' &&
      !isNaN(date.getTime())
    );
  };

  useEffect(() => {
    const storedPasscode = localStorage.getItem('_abcxyz');
    const localTime = localStorage.getItem('_abc123');

    if (!storedPasscode || !localTime) return;

    const usedDate = atob(localTime);
    if (!isValidDate(usedDate)) return;

    if (!checkIsTimeUp(usedDate)) {
      handleClose();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="passcode-container">
      <div className="popup-passcode">
        <div className="popup-passcode-content">
          <h1 className="popup-passcode-title">{t('enter_passcode')}</h1>
          <form onSubmit={handleSubmit} className="form-passcode">
            <div className="input-container">
              <input
                type="password"
                value={passcode}
                onChange={handlePasscodeChange}
                placeholder={t('please_enter_your_passcode_here')}
                className="input-passcode"
                ref={(element) => element?.focus?.()}
              />
              <p className={`error-message`}>{errorMessage}</p>
            </div>
            <button
              type="submit"
              className={`access-popup-pc ${passcode === '' ? 'disabled' : ''}`}
              disabled={passcode === ''}
            >
              {t('access')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupPasscode;
